import { useFetchUserQuery } from "../../store";

function WelcomePage() {
    const { data, error } = useFetchUserQuery();
    let renderedContent
    if (error) { //eğer kullanıcı giriş yapmadıysa
        console.log('fetch user error!!!');
        renderedContent = <div>User not authorized</div>
    } else if (data) { //eğer giriş yapıldıysa
        renderedContent = <div>Welcome to Patara Web</div>
    }

    return (
        <div className="px-5 py-3">
            <div>
                Welcome Page
            </div>
            <div>
                {renderedContent}
            </div>
        </div>
    )
}
export default WelcomePage